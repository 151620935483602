import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator, Image, View, useTheme } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import Report from './Report/Report.js';
import HarvestRequest from './Harvest-Request/HarvestRequest.js';
import NavigationBar from './Reusablecomponents/NavigationBar.js';
import Sidebar from './Reusablecomponents/Sidebar.js';
import ImageTracker from './ImageTracker/ImageTracker.js';
import Gallery from './ImageTracker/Gallery.js';
import ImageComparisonPage from './ImageTracker/ImageComparision.js';
import ImageDetail from './ImageTracker/ImageDetail.js';
import ImageTrackerPopup from './ImageTracker/ImageTrackerPopup.js';
import harvestLogo from './Harvest_Logo.png';
import { UserService } from './api.js';
import OrganizationService from "./organizationService";
import './App.css';

I18n.putVocabulariesForLanguage('en', {
  'Sign in': 'Login',
});

const formFields = {
  signIn: {
    username: {
      placeholder: 'Email',
      isRequired: true,
      label: ''
    },
    password: {
      placeholder: 'Password',
      isRequired: true,
      label: ''
    },
  },
  resetPassword: {
    username: {
      placeholder: 'email',
      isRequired: true,
      label: ''
    }
  },
};

const SignInHeader = () => {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.xxl}>
      <Image alt="Harvest logo" src={harvestLogo} />
    </View>
  );
};

const ForgotPasswordHeader = () => {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.xxl}>
      <Image alt="Harvest logo" src={harvestLogo} />
      <h2>Forgot Password?</h2>
      <p>Please enter the email address associated with your HarvestAi account, then check your inbox for password reset instructions.</p>
    </View>
  );
};

const App = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [showNavigationIcons, setShowNavigationIcons] = useState(true);
  const [harvestRequest, setHarvestRequest] = useState();
  const [orgIdOrUuid, setOrgIdOrUuid] = useState(null);
  const [loading, setLoading] = useState(true);
  const isHarvestRequestEnabled = OrganizationService.isFeatureEnabled(orgIdOrUuid, "harvestRequest");
  const isCropInspectionEnabled = OrganizationService.isFeatureEnabled(orgIdOrUuid, "cropInspection");

  const fetchOrganization = async () => {
    try {
      const organizationUuid = await UserService.fetchOrganizationId();
      setOrgIdOrUuid(organizationUuid);
    } catch (error) {
      console.error("Failed to fetch organization ID:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <div className="App">
      <NavigationBar
        toggleDrawer={toggleDrawer}
        showNavigationIcons={showNavigationIcons}
        harvestRequest={harvestRequest}
        setHarvestRequest={setHarvestRequest}
        orgIdOrUuid={orgIdOrUuid}
      />
      <div style={{ display: 'flex' }}>
        {isDrawerOpen && (
          <Sidebar isDrawerOpen={isDrawerOpen} orgIdOrUuid={orgIdOrUuid} harvestRequest={harvestRequest} setHarvestRequest={setHarvestRequest} />
        )}
        <div className="content-container" style={{ flex: 1 }}>
          <Routes>
            <Route path="/report" element={<Report orgIdOrUuid={orgIdOrUuid} />} />
            {isHarvestRequestEnabled && <Route path="/harvest-request" element={<HarvestRequest setHarvestRequest={setHarvestRequest} />} />}
            {isCropInspectionEnabled && <Route path="/image-tracker" element={<ImageTracker setIsDrawerOpen={setIsDrawerOpen} setShowNavigationIcons={setShowNavigationIcons} />} />}
            <Route path="/gallery" element={<Gallery onImageClick={(image) => { setIsDrawerOpen(false); setShowNavigationIcons(false); }} />} />
            <Route path="/image-comparison" element={<ImageComparisonPage />} />
            <Route path="/" element={<Report orgIdOrUuid={orgIdOrUuid} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};


const AuthContainer = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const navigate = useNavigate();

  const handleStateChange = (state) => {
    if (state === 'signedin') {
    } else if (state === 'signedout') {
      navigate('/login');
    }
  };

  return (
    <div style={containerStyle}>
      <Authenticator
        hideDefault={true}
        hideSignUp={true}
        formFields={formFields}
        onStateChange={handleStateChange}
        components={{
          SignIn: {
            Header: SignInHeader,
          },
          ForgotPassword: {
            Header: ForgotPasswordHeader,
            Footer: () => (
              <View textAlign="center">
                <a href="/signin">Back to Sign In</a>
              </View>
            ),
          },
        }}
      >
        <App />
      </Authenticator>
    </div>
  );
};

export default AuthContainer;
