import gemuseringLogo from './Gemusering_Logo.png';
import gbzLogo from './GBZ.png';
const organizations = [
    {
        id: 1,
        name: "Gemuese Produktion Zorbau(GPZ) dev ",
        uuid: "21226f00-bdb2-48b1-9145-43f2982916b3",
        features: {
            report: true,
            harvestRequest: true,
            cropInspection: true,
            climateSimulation: true,
            harvestPlanning: true,
        },
        logo: gemuseringLogo,
    },

    {
        id: 2,
        name: "GartenBau Zentrale (GBZ)",
        uuid: "3add61f3-029b-4523-840f-aa19727a2337",
        features: {
            report: true,
            harvestRequest: false,
            cropInspection: false,
            climateSimulation: false,
            harvestPlanning: false,
        },
        logo: gbzLogo,
    },
    {
        id: 3,
        name: "Gemuese Produktion Zorbau(GPZ) staging ",
        uuid: "4e8d1f4b-4346-46cf-a25b-859d41269515",
        features: {
            report: true,
            harvestRequest: true,
            cropInspection: true,
            climateSimulation: false,
            harvestPlanning: true,
        },
        logo: gemuseringLogo,
    },
    {
        id: 4,
        name: "Gemuese Produktion Zorbau(GPZ) production ",
        uuid: "b951b4f5-7b78-4470-94eb-308c0fba4240",
        features: {
            report: true,
            harvestRequest: true,
            cropInspection: true,
            climateSimulation: false,
            harvestPlanning: true,
        },
        logo: gemuseringLogo,
    },

];

const OrganizationService = {
    getOrganizationByUuid: (uuid) => {
        return organizations.find((org) => org.uuid === uuid);
    },

    getOrganizationById: (id) => {
        return organizations.find((org) => org.id === id);
    },

    isFeatureEnabled: (uuid, feature) => {
        const organization = organizations.find((org) => org.uuid === uuid);
        return organization ? organization.features[feature] || false : false;
    },

    getOrganizationLogo: (uuid) => {
        const organization = organizations.find((org) => org.uuid === uuid);
        return organization ? organization.logo : null;
    },
};

export default OrganizationService;