import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Gallery.css';
import CustomButton from '../Reusablecomponents/Custombutton';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import selectedNoteIcon from '../icons/selected_note.png';
import { CropInspectionApi } from "../api";
import { format } from 'date-fns';

dayjs.extend(weekOfYear);

const WEEKS_PER_PAGE = 3;
const TOTAL_WEEKS = 20;

let fetchId = 0;

const Gallery = ({ onThumbnailClick, selectedWeek, selectedYear, selectedLocation, cultivationZoneID, withNote }) => {
  const [thumbnailsByCW, setThumbnailsByCW] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [isSpecificWeek, setIsSpecificWeek] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [endOfWeeks, setEndOfWeeks] = useState(false);
  const [noMoreImages, setNoMoreImages] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [noDataForSelectedWeek, setNoDataForSelectedWeek] = useState(null);


  const fetchThumbnailsForSelectedWeek = useCallback(async (week, year, selectedLocation, withNote) => {
    const startDate = dayjs().year(year).week(week).startOf('week').format('YYYY-MM-DD');
    const endDate = dayjs().year(year).week(week).endOf('week').format('YYYY-MM-DD');
    let result;
    if (selectedLocation && selectedLocation.name !== "All") {
      result = await CropInspectionApi.fetchCalendarWeekdata(cultivationZoneID, startDate, endDate, selectedLocation, withNote);
    } else {
      result = await CropInspectionApi.fetchCalendarWeekdata(cultivationZoneID, startDate, endDate, null, withNote);
    }

    return {
      [week]: result.filter(item =>
        !selectedLocation ||
        selectedLocation.name === "All" ||
        item.location.uuid === selectedLocation
      )
    };
  }, [cultivationZoneID, withNote]);

  const fetchThumbnailsAllWeek = useCallback(async (initialWeek, endWeek, selectedLocation, withNote) => {
    let result;
    if (selectedLocation && selectedLocation.name !== "All") {
      result = await CropInspectionApi.fetchLatestData(cultivationZoneID, selectedLocation, withNote);
    } else {
      result = await CropInspectionApi.fetchLatestData(cultivationZoneID, null, withNote);
    }

    return result.reduce((acc, item) => {
      const imageDate = dayjs(item.timestamp);
      const cw = imageDate.week();
      acc[cw] = acc[cw] || [];
      acc[cw].push(item);
      return acc;
    }, {});
  }, [cultivationZoneID]);

  const fetchThumbnailsByCW = useCallback(async () => {
    const currentFetchId = ++fetchId;

    setNoDataAvailable(false);

    try {
      let weeksData = [];
      const currentWeek = dayjs().week();
      const currentYear = dayjs().year();
      const pastYear = currentYear - 1;
      let initialWeek = currentWeek;
      let hasData = false;

      const startDate = dayjs()
        .week(currentWeek)
        .startOf("week")
        .format("YYYY-MM-DD");
      const endDate = dayjs()
        .week(currentWeek)
        .endOf("week")
        .format("YYYY-MM-DD");


      let result = await CropInspectionApi.fetchLatestData(
        cultivationZoneID,
        selectedLocation,
        withNote,
        startDate,
        endDate
      );

      if (currentFetchId !== fetchId) return;

      // Grouping images by week
      const groupedByWeek = result.reduce((acc, item) => {
        const imageDate = dayjs(item.timestamp);
        const isTransitionToNewYear =
          imageDate.month() === 11 &&
          imageDate.date() >= 30 &&
          imageDate.year() === pastYear;
        const year = isTransitionToNewYear ? currentYear : imageDate.year();
        const cw = isTransitionToNewYear ? 1 : imageDate.week();
        const key = `${year}-CW${cw}`;

        acc[key] = acc[key] || [];
        acc[key].push(item);
        return acc;
      }, {});

      if (selectedWeek !== null && selectedWeek !== undefined) {
        const selectedResult = await fetchThumbnailsForSelectedWeek(
          selectedWeek,
          selectedYear,
          selectedLocation,
          withNote
        );
        if (currentFetchId !== fetchId) return;

        if (
          selectedResult[selectedWeek] &&
          selectedResult[selectedWeek].length > 0
        ) {
          const startOfWeek = dayjs()
            .week(selectedWeek)
            .year(selectedYear)
            .startOf("week")
            .format("YYYY-MM-DD");
          const endOfWeek = dayjs()
            .week(selectedWeek)
            .year(selectedYear)
            .endOf("week")
            .format("YYYY-MM-DD");
          weeksData.push({
            dateKey: `CW ${selectedWeek}, ${startOfWeek} - ${endOfWeek}`,
            thumbnails: selectedResult[selectedWeek],
          });
          hasData = true;
        } else {
          const startOfWeek = dayjs()
            .week(selectedWeek)
            .year(selectedYear)
            .startOf("week")
            .format("YYYY-MM-DD");
          const endOfWeek = dayjs()
            .week(selectedWeek)
            .year(selectedYear)
            .endOf("week")
            .format("YYYY-MM-DD");
          setNoDataForSelectedWeek({
            week: selectedWeek,
            startDate: startOfWeek,
            endDate: endOfWeek,
          });
        }
        setIsSpecificWeek(true);
      } else {
        const weeksDataFormatted = Object.entries(groupedByWeek).map(
          ([yearWeekKey, thumbnails]) => {
            const [year, week] = yearWeekKey.split("-CW").map(Number);
            const startOfWeek = dayjs()
              .year(year)
              .week(week)
              .startOf("week")
              .format("YYYY-MM-DD");
            const endOfWeek = dayjs()
              .year(year)
              .week(week)
              .endOf("week")
              .format("YYYY-MM-DD");

            return {
              dateKey: `CW ${week}, ${startOfWeek} - ${endOfWeek}`,
              thumbnails,
            };
          }
        );

        const sortedWeeks = weeksDataFormatted.sort((a, b) => {
          const [aLabel] = a.dateKey.split(", ");
          const [bLabel] = b.dateKey.split(", ");

          const aYear = parseInt(aLabel.split(" ")[2], 10);
          const bYear = parseInt(bLabel.split(" ")[2], 10);
          const aWeek = parseInt(aLabel.split(" ")[1], 10);
          const bWeek = parseInt(bLabel.split(" ")[1], 10);

          if (aYear !== bYear) {
            return bYear - aYear;
          }
          return bWeek - aWeek;
        });

        weeksData = [...weeksData, ...sortedWeeks];

        while (!hasData && initialWeek > 0) {
          const key = `${currentYear}-CW${initialWeek}`;
          const prevKey = `${pastYear}-CW${initialWeek}`;

          if (groupedByWeek[key] || groupedByWeek[prevKey]) {
            hasData = true;
            setIsSpecificWeek(false);
          } else {
            initialWeek--;
          }
        }
      }

      const startIndex = 0;
      const endIndex = Math.min(page * WEEKS_PER_PAGE, TOTAL_WEEKS);
      const pageNumData = weeksData.slice(startIndex, endIndex);

      setThumbnailsByCW((prevThumbnails) => {
        if (page === 1 || isSpecificWeek) {
          return pageNumData;
        } else {
          const filteredWeeksData = pageNumData.filter(
            (newWeek) =>
              !prevThumbnails.some(
                (prevWeek) => prevWeek.dateKey === newWeek.dateKey
              )
          );
          return [...prevThumbnails, ...filteredWeeksData];
        }
      });

      setNoMoreImages(
        endIndex >= weeksData.length || page * WEEKS_PER_PAGE >= TOTAL_WEEKS
      );
      setNoDataAvailable(
        pageNumData.length === 0 && !hasData && !isSpecificWeek
      );
    } catch (error) {
      console.error("Error fetching thumbnails:", error);
      setThumbnailsByCW([]);
      setNoDataAvailable(true);
    } finally {
      if (currentFetchId === fetchId) {
        setLoading(false);
      }
    }
  }, [selectedLocation, selectedWeek, cultivationZoneID, fetchThumbnailsAllWeek, fetchThumbnailsForSelectedWeek, withNote, page
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoadMore(!loading && !isSpecificWeek && !noMoreImages && !endOfWeeks && !noDataAvailable);
    }, 300);
    return () => clearTimeout(timer);
  }, [loading, isSpecificWeek, noMoreImages, endOfWeeks, noDataAvailable]);

  const loadMore = useCallback(() => {
    if (!isSpecificWeek && !noMoreImages && !endOfWeeks && !noDataAvailable) {
      setLoading(true);
      setPage(prevPage => prevPage + 1);
    }
  }, [isSpecificWeek, noMoreImages, endOfWeeks, noDataAvailable]);

  useEffect(() => {
    setLoading(true);
    setPage(1);
    setThumbnailsByCW([]);
    setNoMoreImages(false);
    setEndOfWeeks(false);
    setNoDataAvailable(false);
    setNoDataForSelectedWeek(null);
    fetchThumbnailsByCW();
  }, [selectedLocation, selectedWeek, cultivationZoneID, withNote]);

  useEffect(() => {
    setLoading(true);
    fetchThumbnailsByCW();
  }, [page]);

  const memoizedThumbnails = useMemo(() => thumbnailsByCW, [thumbnailsByCW]);

  return (
    <div className="thumbnail-gallery">
      {memoizedThumbnails.map(({ dateKey, thumbnails }) => (
        <div key={dateKey} className="date-section">
          <h4>{dateKey}</h4>
          <div className="thumbnail-grid">
            {thumbnails.map((thumbnailData, index) => (
              <div key={thumbnailData.uuid} className="thumbnail-wrapper">
                <LazyLoadImage
                  src={thumbnailData.thumbnail_url}
                  alt={`Thumbnail ${index + 1}`}
                  className="thumbnail-item"
                  effect="blur"
                  onClick={() => onThumbnailClick(
                    thumbnailData.thumbnail_url,
                    thumbnails,
                    index,
                    thumbnailData.uuid,
                    thumbnailData.metadata,
                    thumbnailData.timestamp
                  )}
                />
                <div className="thumbnail-label">
                  {thumbnailData.message && (
                    <img src={selectedNoteIcon} alt="Unselected Note" className="unselected-note-icon" />
                  )}
                  {thumbnailData.location?.name ? (
                    <span>
                      {thumbnailData.location.name}
                      <br />
                      {thumbnailData.timestamp && format(thumbnailData.timestamp, 'd MMM yyyy, HH:mm')}
                    </span>
                  ) : (
                    'Location Not Available'
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="thumbnail-gallery">
        {noDataForSelectedWeek && (
          <div className="calendar-week">
            <h4>{`CW ${noDataForSelectedWeek.week}, ${noDataForSelectedWeek.startDate} - ${noDataForSelectedWeek.endDate}`}</h4>
          </div>
        )}
        {noDataForSelectedWeek && (
          <div className="no-data-note">
            <p>No data for selected calendar week.</p>
          </div>
        )}
      </div>
      {loading && (
        <div className="loading-container">
          <div className="loading-indicator">
            <CircularProgress size={40} style={{ color: '#05668D' }} />
          </div>
        </div>
      )}
      {!isSpecificWeek && !noMoreImages && !endOfWeeks && !loading && showLoadMore && !noDataAvailable && (
        <div className="button-gallery" style={{ width: '150px', height: '40px' }}>
          <CustomButton
            label="Load More"
            onClick={loadMore}
            backgroundColor="#6B6B6B"
            hoverBackgroundColor="#05668D"
            activeBackgroundColor="#05668D"
          />
        </div>
      )}
      {noDataAvailable && !isSpecificWeek && (
        <div className="no-data-note">
          <p>No data available for the selected criteria.</p>
        </div>
      )}
      {noMoreImages && !noDataAvailable && !isSpecificWeek && (
        <div className="end-message">
          <p>You have reached the end of displayed weeks. To explore more images, please use the CW dropdown menu.</p>
        </div>
      )}
    </div>
  );
};

export default Gallery;