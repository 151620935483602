import tokenManager from "./TokenManager";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";

const apiRequest = async (endpoint, method = "GET", body = null) => {
  const token = await tokenManager.getToken();
  if (!token) {
    throw new Error("Failed to get the token");
  }
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const config = {
    method,
    headers,
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      config
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Error with ${method} request to ${endpoint}:`, error);
    throw error;
  }
};

export const UserService = {
  fetchOrganizationId: async () => {
    try {
      const userId = await tokenManager.getUserID();
      const getUserResponse = await apiRequest(`/user/${userId}`);
      return getUserResponse["organization_uuid"];
    } catch (error) {
      console.error(`Error fetching organization ID: ${error.message}`);
      throw error;
    }
  },

  fetchUserDetails: async () => {
    try {
      const userId = await tokenManager.getUserID();
      const getUserData = await apiRequest(`/user/${userId}`);
      return getUserData;
    } catch (error) {
      console.error(`Error fetching user details: ${error.message}`);
      throw error;
    }
  },

  fetchLoginData: async () => {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();
      return userInfo.attributes;
    } catch (error) {
      console.error("Error fetching user information:", error);
      throw new Error("Failed to fetch user information");
    }
  },
};

export const FacilityApi = {
  fetchSites: async (organizationId) => {
    try {
      return await apiRequest(`/organization/${organizationId}/sites`);
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw new Error(`Failed to fetch sites: ${error.message}`);
    }
  },

  fetchGreenhouses: async (organizationId, siteId) => {
    try {
      return await apiRequest(
        `/organization/${organizationId}/site/${siteId}/greenhouses`
      );
    } catch (error) {
      console.error("Error fetching greenhouses:", error);
      throw new Error(`Failed to fetch greenhouses: ${error.message}`);
    }
  },

  fetchCompartments: async (organizationId, siteId, greenhouseId) => {
    try {
      return await apiRequest(
        `/organization/${organizationId}/site/${siteId}/greenhouse/${greenhouseId}/compartments`
      );
    } catch (error) {
      console.error("Error fetching compartments:", error);
      throw new Error(`Failed to fetch compartments: ${error.message}`);
    }
  },

  fetchCrops: async (
    organizationId,
    siteId,
    greenhouseId,
    compartmentId,
    isImageTracker = false
  ) => {
    try {
      const endpoint = isImageTracker
        ? `/image-tracker/cultivation-zones?org-uuid=${organizationId}&site-id=${siteId}&greenhouse-id=${greenhouseId}&compartment-id=${compartmentId}`
        : `/organization/${organizationId}/site/${siteId}/greenhouse/${greenhouseId}/compartment/${compartmentId}/cultivation-zones`;
      return await apiRequest(endpoint);
    } catch (error) {
      console.error("Error fetching crops:", error);
      throw new Error(`Failed to fetch crops: ${error.message}`);
    }
  },
};

export const ReportApi = {
  fetchPrediction: async (
    cropId,
    currentHarvestableFruits,
    futureHarvestableFruits,
    light,
    t_Day,
    t_Night,
    co2
  ) => {
    try {
      const endpoint = `/cultivation-zone/${cropId}/prediction?harvest-volume-current=${currentHarvestableFruits}&harvest-volume-future=${futureHarvestableFruits}&light=${light}&temp-day=${t_Day}&temp-night=${t_Night}&co2=${co2}`;
      return await apiRequest(endpoint);
    } catch (error) {
      console.error("Error fetching yield prediction:", error);
      throw new Error(`Failed to get yield prediction: ${error.message}`);
    }
  },

  fetchHistoryEvents: async (cropId) => {
    try {
      const now = new Date();
      const monthAgo = new Date(now.setMonth(now.getMonth() - 2));
      const endpoint = `/cultivation-zone/${cropId}/harvest-events?start-date=${monthAgo.toISOString()}&end-date=${new Date().toISOString()}`;
      return await apiRequest(endpoint);
    } catch (error) {
      console.error("Error fetching history events:", error);
      throw new Error(`Failed to fetch history events: ${error.message}`);
    }
  },

  fetchSensorData: async (cropId) => {
    try {
      const now = new Date();
      const monthAgo = new Date(now.setMonth(now.getMonth() - 2));
      const endpoint = `/cultivation-zone/${cropId}/climate-data?start-date=${monthAgo.toISOString()}&end-date=${new Date().toISOString()}`;
      return await apiRequest(endpoint);
    } catch (error) {
      console.error("Error fetching sensor data:", error);
      return null;
    }
  },
};

export const HarvestRequestApi = {
  fetchAllCrops: async () => {
    try {
      return await apiRequest("/crops");
    } catch (error) {
      console.error("Error fetching crops:", error);
      throw new Error(`Failed to fetch crops: ${error.message}`);
    }
  },

  fetchHarvestRequests: async () => {
    try {
      const organizationUuid = await UserService.fetchOrganizationId();
      return await apiRequest(
        `/organization/${organizationUuid}/harvest-requests`
      );
    } catch (error) {
      console.error("Error fetching harvest requests:", error);
      throw new Error(`Failed to fetch harvest requests: ${error.message}`);
    }
  },

  createHarvestRequest: async (requestData) => {
    try {
      const organizationUuid = await UserService.fetchOrganizationId();
      return await apiRequest(
        `/organization/${organizationUuid}/harvest-requests`,
        "POST",
        requestData
      );
    } catch (error) {
      console.error("Error creating harvest request:", error);
      throw new Error(`Failed to create harvest request: ${error.message}`);
    }
  },

  updateHarvestRequest: async (requestId, updatedData) => {
    try {
      const organizationUuid = await UserService.fetchOrganizationId();
      return await apiRequest(
        `/organization/${organizationUuid}/harvest-requests/${requestId}`,
        "PUT",
        updatedData
      );
    } catch (error) {
      console.error("Error updating harvest request:", error);
      throw new Error(`Failed to update harvest request: ${error.message}`);
    }
  },
};

export const CropInspectionApi = {
  fetchLocations: async (cultivationZoneId) => {
    try {
      return await apiRequest(`/image-tracker/${cultivationZoneId}/locations`);
    } catch (error) {
      console.error("Error fetching locations:", error);
      throw new Error(`Failed to fetch locations: ${error.message}`);
    }
  },

  fetchCalendarWeekdata: async (
    cultivationZoneId,
    startDate,
    endDate,
    locationUuid = null,
    withMessage = false
  ) => {
    try {
      let endpoint = `/image-tracker/${cultivationZoneId}/images/range?start-date=${startDate}&end-date=${endDate}&thumb=true&with-message=${withMessage}`;

      if (locationUuid) {
        endpoint += `&image-location-uuid=${locationUuid}`;
      }

      return await apiRequest(endpoint);
    } catch (error) {
      console.error("Error fetching calendar week data:", error);
      throw new Error(`Failed to fetch calendar week data: ${error.message}`);
    }
  },

  fetchLatestData: async (
    cultivationZoneId,
    locationUuid = null,
    withMessage
  ) => {
    try {
      let endpoint = `/image-tracker/${cultivationZoneId}/images/latest?number=150&skip=0&thumb=true&with-message=${withMessage}`;

      if (locationUuid) {
        endpoint += `&image-location-uuid=${locationUuid}`;
      }

      return await apiRequest(endpoint);
    } catch (error) {
      console.error("Error fetching latest data:", error);
      throw new Error(`Failed to fetch latest data: ${error.message}`);
    }
  },
  fetchImagesForCalender1: async (
    startDate,
    cropId,
    location,
    withMessage = false
  ) => {
    try {
      const endDate = dayjs(startDate).add(1, "day").format("YYYY-MM-DD");
      let endpoint = `/image-tracker/${cropId}/images/range?start-date=${startDate}&end-date=${endDate}&with-message=${withMessage}`;

      if (location) {
        endpoint += `&image-location-uuid=${location}`;
      }

      return await apiRequest(endpoint);
    } catch (error) {
      console.error("Error fetching images for Calendar 1:", error);
      throw new Error(
        `Failed to fetch images for Calendar 1: ${error.message}`
      );
    }
  },

  fetchImagesForCalender2: async (
    startDate,
    cropId,
    location,
    withMessage = false
  ) => {
    try {
      const endDate = dayjs(startDate).add(1, "day").format("YYYY-MM-DD");
      let endpoint = `/image-tracker/${cropId}/images/range?start-date=${startDate}&end-date=${endDate}&with-message=${withMessage}`;

      if (location) {
        endpoint += `&image-location-uuid=${location}`;
      }

      return await apiRequest(endpoint);
    } catch (error) {
      console.error("Error fetching images for Calendar 2:", error);
      throw new Error(
        `Failed to fetch images for Calendar 2: ${error.message}`
      );
    }
  },

  fetchImageByUuid: async (uuid, cropId, withMessage = false) => {
    try {
      const endpoint = `/image-tracker/${cropId}/images/${uuid}?thumb=false&with-message=${withMessage}`;
      return await apiRequest(endpoint);
    } catch (error) {
      console.error("Error fetching full-size image:", error);
      throw new Error(`Failed to fetch full-size image: ${error.message}`);
    }
  },

  updateImageMessage: async (
    uuid,
    cropId,
    updatedApiData,
    withMessage = false
  ) => {
    try {
      const endpoint = `/image-tracker/${cropId}/images`;
      return await apiRequest(endpoint, "PUT", updatedApiData);
    } catch (error) {
      console.error("Error updating the message:", error);
      throw new Error(`Failed to update the message: ${error.message}`);
    }
  },
};
