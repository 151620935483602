import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Popover, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import harvestLogo from '../Harvest_Logo.png';
import gemuseringLogo from '../Gemusering_Logo.png';
import { ReactComponent as MenuIcon } from '../icons/MenuIcon.svg';
import { ReactComponent as SearchIcon } from '../icons/SearchIcon.svg';
import { NotificationIcon } from '../icons/NotificationIcon.jsx';
import OrganizationService from '../organizationService.js';
import './NavigationBar.css';

export const InputBaseStyled = styled('input')(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    color: '#ffffff',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function NavigationBar({ setHarvestRequest, harvestRequest, toggleDrawer, showNavigationIcons, orgIdOrUuid }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [organizationLogo, setOrganizationLogo] = useState(null);

  useEffect(() => {
    const logo = OrganizationService.getOrganizationLogo(orgIdOrUuid);
    setOrganizationLogo(logo);
  }, [orgIdOrUuid]);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setUserEmail(null);
      setOrganizationLogo(null);
      handleClosePopover();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        setUserEmail(userInfo.attributes.email);
      } catch (error) {
        console.error('Error fetching user email:', error);
      }
    };

    fetchUserEmail();
  }, []);

  const getInitials = (email) => {
    const emailParts = email.split('@');
    if (emailParts.length === 2) {
      const username = emailParts[0];
      return username.substring(0, 2).toUpperCase();
    }
    return '';
  };

  return (
    <AppBar position="sticky" color="transparent" elevation={0}>
      <Toolbar>
        <div className="logoContainer">
          <IconButton onClick={toggleDrawer} style={{ cursor: 'pointer' }}>
            <MenuIcon className="menuIcon" />
          </IconButton>
          <img src={harvestLogo} alt="Harvest_Logo" className="harvestLogo" />
          <img src={organizationLogo} alt="org_Logo" />
        </div>
        {showNavigationIcons && (
          <>
            <div className="searchContainer">
              <div className="searchIconWrapper">
                <SearchIcon />
              </div>
              <InputBaseStyled placeholder="Search" />
            </div>
            <div style={{ flexGrow: 1 }}></div>
            <div style={{ flexGrow: 1 }}></div>
            <Tooltip
              title={harvestRequest > 0 ? "Got a new harvest request" : "No notifications"}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#F9F9F9",
                    color: "#110202",
                    border: '2px solid #110202',
                    fontSize: '14px',
                    fontWeight: '900',
                    fontFamily: 'Red Hat Display',
                    lineHeight: '20px',
                  },
                },
              }}
            >
              <IconButton color="inherit">
                <NotificationIcon
                  harvestRequest={harvestRequest}
                  setHarvestRequest={setHarvestRequest}
                />
              </IconButton>
            </Tooltip>
            <IconButton
              color="inherit"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <a className="initials-button">
                {userEmail ? getInitials(userEmail) : ''}
              </a>
            </IconButton>
            <Popover
              className="custom-popover"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div className="sign-out">
                <Typography variant="subtitle1">{userEmail}</Typography>
                <button onClick={handleSignOut}>Sign Out</button>
              </div>
            </Popover>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
