import React, { useState, useEffect } from 'react';
import { Typography, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useNavigate } from 'react-router-dom';
import ButtonStack from '../Reusablecomponents/CustomButtonStack';
import CropSelector from "../Reusablecomponents/CropSelector";
import MultiDatePicker from '../Reusablecomponents/MultiDatePicker';
import dayjs from 'dayjs';
import { CropInspectionApi } from "../api";
import './ImageTrackerPopup.css';

function ImageTrackerPopup(props) {
    const [cropId, setCropId] = useState(null);
    const navigate = useNavigate();
    
    const [startDate, setStartDate] = useState(dayjs().startOf('day'));
    const [endDate, setEndDate] = useState(dayjs().startOf('day'));
    const [images, setImages] = useState([]);
    const [locationId, setLocationId] = useState(null);
    const [siteId, setSiteId] = useState(null);
    const [greenhouseId, setGreenhouseId] = useState(null);
    const [compartmentId, setCompartmentId] = useState(null);


    useEffect(() => {
        setLocationId(props.selectedLocation?.uuid || null);
        setSiteId(props.selectedSite?.id || null);
        setGreenhouseId(props.selectedGreenhouse?.id || null);
        setCompartmentId(props.selectedCompartment?.id || null);
        setCropId(props.selectedCrop?.id || null);

        setImages([]);
        setStartDate(dayjs().startOf('day'));
        setEndDate(dayjs().startOf('day'));
    }, [props.open, props.selectedLocation, props.selectedSite, props.selectedGreenhouse, props.selectedCompartment, props.selectedCrop]);


    const handleApplyClick = async () => {

        const startDateFormatted1 = dayjs(startDate).format('YYYY-MM-DD');
        const endDateFormatted2 = dayjs(endDate).format('YYYY-MM-DD');
        try {
            let calender1Images, calender2Images;

            if (locationId) {
                calender1Images =
                  await CropInspectionApi.fetchImagesForCalender1(
                    startDateFormatted1,
                    cropId,
                    locationId
                  );
                calender2Images =
                  await CropInspectionApi.fetchImagesForCalender2(
                    endDateFormatted2,
                    cropId,
                    locationId
                  );
            } else {
                calender1Images =
                  await CropInspectionApi.fetchImagesForCalender1(
                    startDateFormatted1,
                    cropId
                  );
                calender2Images =
                  await CropInspectionApi.fetchImagesForCalender2(
                    endDateFormatted2,
                    cropId
                  );
            }

            const imageOne = calender1Images.length > 0 ? {
                url: calender1Images[0].image_url,
                timestamp: calender1Images[0].timestamp,
                uuid: calender1Images[0].uuid
            } : null;

            const imageTwo = calender2Images.length > 0 ? {
                url: calender2Images[0].image_url,
                timestamp: calender2Images[0].timestamp,
                uuid: calender2Images[0].uuid
            } : null;

            const fetchedImages = [imageOne, imageTwo].filter(Boolean);

            props.onClose();

            navigate('/image-comparison', {
                state: {
                    startDate: startDateFormatted1,
                    endDate: endDateFormatted2,
                    images: fetchedImages,
                    compartmentId,
                    cropId,
                    locationId,
                    siteId,
                    greenhouseId,

                }
            });
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    return props.open ? (
      <div className="popup-div">
        <div className="popup-header">
          <Typography variant="h6">ImageTracker</Typography>
        </div>
        <Stack spacing={2} p={2} className="popover-body">
          <div
            className="crop-selector-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <CropSelector
              setCropId={setCropId}
              onChangeCrop={(cropId) => {
                setCropId(cropId);
              }}
              onSiteChange={(siteId) => setSiteId(siteId)}
              onGreenhouseChange={(greenhouseId) =>
                setGreenhouseId(greenhouseId)
              }
              onCompartmentChange={(compartmentId) =>
                setCompartmentId(compartmentId)
              }
              onLocationChange={(locationUuid) => setLocationId(locationUuid)}
              showLocation={true}
              showCW={false}
              direction="vertical"
              initialLocationId={props.selectedLocation?.uuid}
              initialCropId={props.selectedCrop?.id}
              initialSiteId={props.selectedSite?.id}
              initialGreenhouseId={props.selectedGreenhouse?.id}
              initialCompartmentId={props.selectedCompartment?.id}
              isImageTracker={true}
            />
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MultiDatePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
          </LocalizationProvider>
          <ButtonStack
            style={{ marginTop: "35px" }}
            onButtonClick={handleApplyClick}
            cancelLabel="Cancel"
            sendLabel="Apply"
            height="42px"
            width="140px"
            fontWeight="bold"
            onCancelClick={props.onClose}
          />
        </Stack>
      </div>
    ) : null;
}

export default ImageTrackerPopup;
