import React, { useEffect, useState } from "react";
import { UserService, FacilityApi, CropInspectionApi } from "../api";
import Dropdown from "./Dropdown";
import './CropSelector.css';
import unselectedNote from '../icons/unselected_note.png';
import selectedNote from '../icons/selected_note.png';
import FruitTable from "./FruitTable";



const CropSelector = (props) => {
    const { isImageTracker = false, orgIdOrUuid } = props;
    const [organizationId, setOrganizationId] = useState('');

    const [site, setSite] = useState('');
    const [siteList, setSiteList] = useState([]);

    const [greenhouse, setGreenhouse] = useState('');
    const [greenhouseList, setGreenhouseList] = useState([]);

    const [compartment, setCompartment] = useState('');
    const [compartmentList, setCompartmentList] = useState([]);

    const [crop, setCrop] = useState('');
    const [cropList, setCropList] = useState([]);

    const [location, setLocation] = useState('All');
    const [locationList, setLocationList] = useState([]);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState("All");

    const [weeks, setWeeks] = useState([]);
    const [currentWeek, setCurrentWeek] = useState(null);
    const [withNote, setWithNote] = useState(false);
    const [isHovered, setIsHovered] = useState(false);



     useEffect(() => {
       const currentDate = new Date();
       const currentYear = currentDate.getFullYear();
       const startOfYear = new Date(currentYear, 0, 1);
       const pastDaysOfYear = (currentDate - startOfYear) / 86400000;
       const currentWeek = Math.ceil(
         (pastDaysOfYear + startOfYear.getDay() + 1) / 7
       );

       const generateWeeks = () => {
         const currentYearWeeks = Array.from(
           { length: currentWeek },
           (_, i) => `CW ${currentWeek - i}`
         );

         const previousYearWeeks = Array.from(
           { length: 52 - currentWeek + 1 },
           (_, i) => `CW ${52 - i} ${currentYear - 1}`
         );

         return ["All", ...currentYearWeeks, ...previousYearWeeks];
       };

       setWeeks(generateWeeks());
       setCurrentWeek(currentWeek);
     }, []);

    const handleSiteChange = (event) => {
        const selectedSiteName = event.target.value;
        setSite(selectedSiteName);
        setLocation('All');
        if (props.onLocationChange) {
          props.onLocationChange(null);
        }

        if (props.onSiteChange) {
          const selectedSiteObj = siteList.find(
            (site) => site.name === selectedSiteName
          );
          props.onSiteChange(selectedSiteObj?.id || null);
        }
    };


    const handleGreenhouseChange = (event) => {
        const selectedGreenhouseName = event.target.value;
        setGreenhouse(selectedGreenhouseName);
        setLocation('All');
        if (props.onLocationChange) {
          props.onLocationChange(null);
        }

        if (props.onGreenhouseChange) {
          const selectedGreenhouseObj = greenhouseList.find(
            (greenhouse) => greenhouse.name === selectedGreenhouseName
          );
          props.onGreenhouseChange(selectedGreenhouseObj?.id || null);
        }
    };


    const handleCompartmentChange = (event) => {
        const selectedCompartmentName = event.target.value;
        setCompartment(selectedCompartmentName);
        setLocation('All');
        if (props.onLocationChange) {
          props.onLocationChange(null);
        }

        if (props.onCompartmentChange) {
          const selectedCompartmentObj = compartmentList.find(
            (comp) => comp.name === selectedCompartmentName
          );
          props.onCompartmentChange(
            selectedCompartmentObj?.id || null
          );
        }
    };

    const handleCropChange = (event) => {
        cropList.filter(c => event.target.value === c.name).forEach(c => {
            setCrop(c);
            props.setCropId(c.id);
            props.setYeildm2(c.cultivation_area);
            setLocation('All');
            if (props.onLocationChange) {
              props.onLocationChange(null);
            }
        });
    };
    const handleLocationChange = (event) => {
        const selectedLocationName = event.target.value;
        setLocation(selectedLocationName);

        if (props.onLocationChange) {
          if (selectedLocationName === "All") {
            props.onLocationChange(null);
          } else {
            const selectedLocationObj = locationList.find(
              (loc) => loc.name === selectedLocationName
            );
            props.onLocationChange(selectedLocationObj?.uuid || null);
          }
        }
    };

    const handleWeekChange = (event) => {
      const value = event.target.value;
      setSelectedDropdownValue(value);
      if (value === "All") {
        setCurrentWeek(null);
        props.onWeekChange(null, null);
      } else {
        const match = value.match(/CW (\d+)(?: (\d+))?/);
        if (match) {
          const selectedWeek = parseInt(match[1], 10);
          const selectedYear = match[2]
            ? parseInt(match[2], 10)
            : new Date().getFullYear();
          setCurrentWeek(selectedWeek);
          props.onWeekChange(selectedWeek, selectedYear);
        }
      }
    };

    const handleNoteClick = () => {
        const newWithNote = !withNote;
        setWithNote(newWithNote);
        props.handleWithNoteChange(newWithNote);
    };


    useEffect(() => {
        const fetchData = async () => {
            const organizationId = await UserService.fetchOrganizationId();
            setOrganizationId(organizationId);
        }
        fetchData().catch(err => console.error(err));
    }, [])

    useEffect(() => {
        if (!organizationId) {
            return;
        }

        const fetchData = async () => {
            const sites = await FacilityApi.fetchSites(organizationId);
            setSiteList([...sites]);

            if (sites.length === 0) {
                return;
            }

            if (props.initialSiteId) {
                const initialSite = sites.find(site => site.id === props.initialSiteId);
                if (initialSite) {
                    setSite(initialSite.name);
                    return;
                }
            }
            setSite(sites[0].name);
        };

        fetchData().catch(err => console.error(err));
    }, [organizationId, props.initialSiteId]);


    useEffect(() => {
        if (!organizationId || !site || !siteList) {
            return;
        }

        const siteData = siteList.find(s => s.name === site);
        if (!siteData) {
            return;
        }
        const siteId = siteData.id;

        const fetchData = async () => {
            const greenhouses = await FacilityApi.fetchGreenhouses(organizationId, siteId);
            setGreenhouseList([...greenhouses]);

            if (greenhouses.length === 0) {
                return;
            }

            if (props.initialGreenhouseId) {
                const initialGreenhouse = greenhouses.find(greenhouse => greenhouse.id === props.initialGreenhouseId);
                if (initialGreenhouse) {
                    setGreenhouse(initialGreenhouse.name);
                    return;
                }
            }

            setGreenhouse(greenhouses[0].name);
        };

        fetchData().catch(err => console.error(err));
    }, [organizationId, site, siteList, props.initialGreenhouseId]);


    useEffect(() => {
        if (!organizationId) {
            return;
        }
        const siteData = siteList.filter(s => s.name === site)[0];
        if (!siteData) {
            return;
        }
        const siteId = siteData.id;

        const greenhouseData = greenhouseList.filter(g => g.name === greenhouse)[0];
        if (!greenhouseData) {
            return;
        }
        const greenhouseId = greenhouseData.id;

        const fetchData = async () => {
            const compartments = await FacilityApi.fetchCompartments(organizationId, siteId, greenhouseId);
            setCompartmentList(compartments);
            if (compartments.length === 0) {
                return;
            }
            if (props.initialCompartmentId) {
                const initialCompartment = compartments.find(comp => comp.id === props.initialCompartmentId);
                if (initialCompartment) {
                    setCompartment(initialCompartment.name);
                    return;
                }
            }
            setCompartment(compartments[0].name);
        }

        fetchData().catch(err => console.error(err));
    }, [organizationId, site, siteList, greenhouse, greenhouseList, props.initialCompartmentId])



    useEffect(() => {

        if (!organizationId) {
            return;
        }
        const siteData = siteList.filter(s => s.name === site)[0];
        if (!siteData) {
            return;
        }
        const siteId = siteData.id;

        const greenhouseData = greenhouseList.filter(g => g.name === greenhouse)[0];
        if (!greenhouseData) {
            return;
        }
        const greenhouseId = greenhouseData.id;

        const compartmentData = compartmentList.filter(c => c.name === compartment)[0];
        if (!compartmentData) {
            return;
        }
        const compartmentId = compartmentData.id;

        const fetchData = async () => {
            const crops = await FacilityApi.fetchCrops(organizationId, siteId, greenhouseId, compartmentId, isImageTracker);
            setCropList(crops);
            if (crops.length === 0) {
                setCrop(null);
                props.setCropId(null);
                props.setYeildm2(null);
                return;
            }
            const initialCrop = crops.find(crop => crop.id === props.initialCropId);

            if (initialCrop) {
                setCrop(initialCrop);
                props.setCropId(initialCrop.id);
                props.setYeildm2(initialCrop.cultivation_area);
            } else {

                setCrop(crops[0]);
                props.setCropId(crops[0].id);
                props.setYeildm2(crops[0].cultivation_area)
            }
            if (props.onChangeCrop) {
             props.onChangeCrop(
               initialCrop ? initialCrop.id : crops[0].id
             );
            }
            if (props.onChange) {
                props.onChange(site, greenhouse, compartment, crops[0].name);
            }

        };
        fetchData().catch(err => console.error(err));
    }, [organizationId, site, siteList, greenhouse, greenhouseList, compartment, compartmentList, props.setCropId, props.setYeildm2])

    //Location
    useEffect(() => {
        if (!crop || !crop.id) {
            setLocationList([{ name: 'All', uuid: 'all' }]);
            setLocation('All');
            return;
        }

        const fetchLocationData = async () => {
            try {
                const locations = await CropInspectionApi.fetchLocations(crop.id);
                const updatedLocationList = [{ name: 'All', uuid: 'all' }, ...locations];
                setLocationList(updatedLocationList);

                if (locations.length === 0) {

                } else if (locations.length > 0 && !location) {

                    setLocation('All');
                }
                if (props.initialLocationId) {
                    const initialLocation = updatedLocationList.find(loc => loc.uuid === props.initialLocationId);
                    if (initialLocation) {
                        setLocation(initialLocation.name);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchLocationData();

    }, [crop, organizationId, site, siteList, greenhouse, greenhouseList, compartment, compartmentList, props.setCropId, location, props.initialLocationId]);
    {
        props.showNote && (
            <div
                className="with-note"
                onClick={() => {
                    setWithNote(true);

                }}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
                <img src={unselectedNote} alt="With note" />
                <span style={{ marginLeft: '8px' }}>With Note</span>
            </div>
        )
    }



    return (
      <div
        className={`crop-selector-container ${
          props.direction === "vertical" ? "vertical" : "horizontal"
        }`}
      >
        <div
          className={`dropdown-container ${
            props.direction === "vertical" ? "vertical" : "horizontal"
          }`}
        >
          <Dropdown
            name="Site"
            values={siteList.map((s) => `${s.name}`)}
            selectedValue={`${site}`}
            showColon={true}
            handleChange={handleSiteChange}
          />
        </div>
        <div
          className={`dropdown-container ${
            props.direction === "vertical" ? "vertical" : "horizontal"
          }`}
        >
          <Dropdown
            name="Greenhouse"
            values={greenhouseList.map((g) => g.name)}
            selectedValue={greenhouse}
            showColon={true}
            handleChange={handleGreenhouseChange}
          />
        </div>
        {/* <div className={`dropdown-container ${direction === "vertical" ? "vertical" : "horizontal"}`}>
                <Dropdown
                    name="Compartment"
                    values={compartmentList.map(c => c.name)}
                    selectedValue={compartment}
                    showColon={true}
                    handleChange={handleCompartmentChange}
                />
            </div>*/}

        <div
          className={`dropdown-container ${
            props.direction === "vertical" ? "vertical" : "horizontal"
          }`}
        >
          <Dropdown
            name="Fruits"
            values={cropList.map((crop) => (crop ? crop.name : ""))}
            selectedValue={crop ? crop.name : ""}
            showColon={true}
            handleChange={handleCropChange}
            orgIdOrUuid={orgIdOrUuid}
          />
        </div>

        {props.showLocation && (
          <div
            className={`dropdown-container ${
              props.direction === "vertical" ? "vertical" : "horizontal"
            }`}
          >
            <Dropdown
              name="Location"
              values={locationList.map((loc) => loc.name)}
              selectedValue={location || "All"}
              showColon={true}
              handleChange={handleLocationChange}
            />
          </div>
        )}
        {props.showCW && (
          <div
            className={`dropdown-container ${
              props.direction === "vertical" ? "vertical" : "horizontal"
            }`}
          >
            <Dropdown
              name="CW"
              values={weeks}
              selectedValue={selectedDropdownValue}
              showColon={true}
              handleChange={handleWeekChange}
            />
          </div>
        )}
        {props.showNote && (
          <button
            className={`with-note ${withNote ? "active" : ""}`}
            onClick={handleNoteClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={isHovered || withNote ? selectedNote : unselectedNote}
              alt="With note"
            />
            <span style={{ marginLeft: "8px" }}>With Note</span>
          </button>
        )}
      </div>
    );
};

export default CropSelector;