import React, { useState, useEffect } from 'react';
import Card from "../Reusablecomponents/Card";
import CustomButton from '../Reusablecomponents/Custombutton';
import CropSelector from "../Reusablecomponents/CropSelector";
import ImageTrackerPopup from './ImageTrackerPopup';
import './ImageTracker.css';
import Gallery from './Gallery';
import Tracker from '../icons/Tracker.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ImageDetail from './ImageDetail';
import { CropInspectionApi, FacilityApi } from "../api";
import { formatRequestedDate } from '../DateFormatter';

const getCurrentWeek = () => {
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const pastDaysOfYear = (currentDate - startOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
};

const ImageTracker = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [showMenuIcon, setShowMenuIcon] = useState(false);
  const [selectedWeekThumbnails, setSelectedWeekThumbnails] = useState([]);
  const [showWeekThumbnails, setShowWeekThumbnails] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [location, setLocation] = useState({});
  const [info, setInfo] = useState({});
  const [timestamp, setTimestamp] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [comment, setComment] = useState(null);
  const [noLocationAvailable, setNoLocationAvailable] = useState(false);
  const [cropId, setCropId] = useState(null);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [selectedSite, setSelectedSite] = useState('');
  const [selectedGreenhouse, setSelectedGreenhouse] = useState('');
  const [selectedCompartment, setSelectedCompartment] = useState('');
  const [selectedCrop, setSelectedCrop] = useState('');
  const [apiData, setApiData] = useState({});
  const [withNote, setWithNote] = useState(false);

  const handleWeekChange = (week, year) => {
    setSelectedWeek(week);
    setSelectedYear(year);
    setNoLocationAvailable(false);
  };

  const handleWithNoteChange = (value) => {
    setWithNote(prev => !prev);
  };

  const handleCommentUpdate = async (newComment, uuid) => {
    setComment(newComment);
    if (enlargedImage) {
      try {
        const uuid = apiData.uuid;
        const updatedApiData = { ...apiData, message: newComment };
        await CropInspectionApi.updateImageMessage(uuid, cropId, updatedApiData);
      } catch (error) {
        console.error('Error updating comment:', error);
      }
    }
  };

  const handleCropSelectorChange = (site, greenhouse, compartment, crop) => {
    setSelectedSite(site);
    setSelectedGreenhouse(greenhouse);
    setSelectedCompartment(compartment);
    setSelectedCrop(crop);
  };

  const handleLocationChange = (location) => {
    if (location === null) {
      setSelectedLocation(null);
      setNoLocationAvailable(true);
    } else {
      setNoLocationAvailable(false);
      setSelectedLocation(location);
    }
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleThumbnailClick = async (thumbnail, thumbnails, index, uuid, location, timestamp, message) => {

    try {
      const data = await CropInspectionApi.fetchImageByUuid(uuid, cropId);
      if (data && data.image_url) {
        setApiData(data);
        setEnlargedImage(data.image_url);
        setShowMenuIcon(true);
        setSelectedWeekThumbnails(thumbnails);
        setShowWeekThumbnails(true);
        setCurrentIndex(index);
        setLocation(data.location.name);
        setTimestamp(timestamp);
        setComment(data.message);
        setSelectedSite(selectedSite);
        setSelectedGreenhouse(selectedGreenhouse);
        setSelectedCompartment(selectedCompartment);
        setSelectedCrop(selectedCrop);
        setNoDataAvailable(false);
        const formattedDate = data.timestamp
          ? formatRequestedDate(data.timestamp, true, true, true, false)
          : 'Invalid Date';
        setInfo({
          site: selectedSite || '',
          greenhouse: selectedGreenhouse || '',
          compartment: selectedCompartment || '',
          fruit: selectedCrop || '',
          location: data.location.name || '',
          created: formattedDate || '',
          comment: data.message || '',
        });
      } else {
        setNoDataAvailable(true);
      }
    } catch (error) {
      console.error('Error fetching full-size image:', error);
      setNoDataAvailable(true);
    }
  };

  const handleBackToGallery = () => {
    setEnlargedImage(null);
    setShowMenuIcon(false);
    setShowWeekThumbnails(false);
  };

  const handleMenuIconClick = () => {
    setShowWeekThumbnails(prevState => !prevState);
  };
  const handleNavigation = async (direction) => {
    const currentWeekThumbnails = selectedWeekThumbnails.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    const currentIndex = currentWeekThumbnails.findIndex(thumb => thumb.uuid === apiData.uuid);
    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < currentWeekThumbnails.length) {
      const newThumbnail = currentWeekThumbnails[newIndex];

      try {

        const data = await CropInspectionApi.fetchImageByUuid(
          newThumbnail.uuid,
          cropId
        );
        if (data && data.image_url) {

          setApiData(data);
          setEnlargedImage(data.image_url);

          const formattedDate = data.timestamp
            ? formatRequestedDate(data.timestamp, true, true, true, false)
            : 'Invalid Date';
          setInfo({
            site: selectedSite || '',
            greenhouse: selectedGreenhouse || '',
            compartment: selectedCompartment || '',
            fruit: selectedCrop || '',
            location: data.location.name || '',
            created: formattedDate || '',
            comment: data.message || '',
          });
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }
  };

  return (
    <div className="center-container">
      <Card className="card-container" height={"130%"} width={"100%"}>
        <div
          className="row"
          style={{ color: "#05668D", fontSize: "32px", fontWeight: "800" }}
        >
          <div className="card-title">Crop Inspection</div>
          <div className="button-container">
            <CustomButton
              label="Image comparison"
              onClick={handleOpenPopup}
              className="request-button"
              backgroundColor="#6B6B6B"
              hoverBackgroundColor="#05668D"
              activeBackgroundColor="#05668D"
              icon={Tracker}
            />
          </div>
        </div>
        <div className="controls-container">
          <div className="crop-selector">
            <CropSelector
              setCropId={setCropId}
              showLocation={true}
              showNote={true}
              showCW={true}
              onWeekChange={handleWeekChange}
              onLocationChange={handleLocationChange}
              onChange={handleCropSelectorChange}
              handleWithNoteChange={handleWithNoteChange}
              isImageTracker={true}
            />
          </div>
          {showMenuIcon && (
            <div className="icons-container">
              <button
                style={{ color: "black" }}
                className="toggle-thumbnails-button"
                onClick={handleMenuIconClick}
              >
                <MenuIcon />
              </button>
              <button className="cancel-button" onClick={handleBackToGallery}>
                <CloseIcon />
              </button>
            </div>
          )}
        </div>
        {noDataAvailable ? (
          <div className="no-data-message">No data available.</div>
        ) : enlargedImage ? (
          <ImageDetail
            enlargedImage={enlargedImage}
            handleBackToGallery={handleBackToGallery}
            showWeekThumbnails={showWeekThumbnails}
            selectedWeekThumbnails={selectedWeekThumbnails}
            currentIndex={currentIndex}
            handleNavigation={handleNavigation}
            info={info}
            onCommentUpdate={handleCommentUpdate}
          />
        ) : (
          <Gallery
            onThumbnailClick={handleThumbnailClick}
            cultivationZoneID={cropId}
            selectedLocation={selectedLocation}
            selectedWeek={selectedWeek}
            selectedYear={selectedYear}
            setNoDataAvailable={setNoDataAvailable}
            withNote={withNote}
          />
        )}
      </Card>

      <ImageTrackerPopup
        open={openPopup}
        onClose={handleClosePopup}
        handleBackToGallery={handleBackToGallery}
      />
    </div>
  );
};

export default ImageTracker;